/**
 * Smooth scroll to element
 * @param {String} str
 */
export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const bold = /\*\*(.*?)\*\*/gm;

export const markdownToHtml = (input = '') => {
  return input
    .replace(bold, '<strong>$1</strong>')
    .replace(/[\r\n]/g, ' <br />');
};

export const replaceValuesInRichText = (richText, params) => {
  let text = richText.text;

  if (typeof text === 'string') {
    params.forEach((param) => {
      text = text.replace(new RegExp(param.key, 'g'), param.value);
    });
  }

  return {
    ...richText,
    text,
    content: Array.isArray(richText.content)
      ? richText.content.map((item) => {
          return replaceValuesInRichText(item, params);
        })
      : richText.content,
  };
};
