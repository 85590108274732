import { useEffect } from 'react';

export const isInStoryblok = () => {
  return (
    typeof window !== 'undefined' &&
    window?.location?.search?.includes?.('_storyblok')
  );
};

export const useStoryblok = ({ draftModeEnabled }) => {
  useEffect(() => {
    if (isInStoryblok()) {
      if (typeof document !== 'undefined') {
        document.documentElement?.classList?.add?.('--in-storyblok');
      }
    }
  }, []);

  useEffect(() => {
    // If not an iframe or a Vercel Preview deployment, turn off Draft Mode
    if (
      draftModeEnabled &&
      process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview' &&
      window === parent
    ) {
      location.href = `/api/draft-mode/disable?redirect=${location.pathname}`;
    }
  }, []);
};
