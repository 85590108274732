/**
 * Check if Storyblok link is defined
 */
export const isLink = (link) => {
  if (link?.linktype === 'story' && !link?.id) {
    return false;
  }

  if (link?.linktype === 'url' && !link?.url) {
    return false;
  }

  return !!link;
};
